import { React, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

function Toast() {
    const [show, setShow] = useState(true)

    useEffect(() => {
        const toastDismissed = JSON.parse(localStorage.getItem('toastDismissed'));
        if (toastDismissed) {
            setShow(false);
        }
    }, []);

    function closeModal() {
        localStorage.setItem('toastDismissed', JSON.stringify(true));
        setShow(false)
    };

    function openModal() {
        localStorage.setItem('toastDismissed', JSON.stringify(false));
        setShow(true)
    };

    return (
        <>
            <div
                aria-live="assertive"
                className="fixed right-0 bottom-0 z-10 flex items-end w-full px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {show ? (
                         <Transition className="w-full max-w-xl" show={show}>
                            <div className="pointer-events-auto w-full rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="ml-3 w-0 flex-1">
                                            <p className="text-p3 text-primary font-bold">
                                                Welcome to Sound Map for a Changing Landscape
                                            </p>
                                            <p className="mt-1 text-sm">
                                                This is a platform for sharing audio and images documenting the changes to the places that are important to us. You are invited to upload photos, interviews, recollections, or field recordings to share with other users.
                                                <br /><br />
                                                To start, click on a location on the map. This will set a pin. Once you’ve set the pin, you can upload files from your mobile device or computer. You can also create an audio recording directly from the upload window. Once the files to upload and/or recording(s) are done, submit your pin. It will be reviewed by our content moderators, then shared with everyone through the map interface.
                                                <br /><br />
                                                If you have any questions or run into problems, reach out to us at <a href="mailto:soundmapcl@gmail.com" className='text-primary'>soundmapcl@gmail.com</a>
                                            </p>
                                        </div>
                                        <div className="ml-4 flex flex-shrink-0">
                                            <button
                                                type="button"
                                                onClick={closeModal}
                                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                <span className="sr-only">Close</span>
                                                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    ) : (
                        <button type="button" className='relative w-8 h-8 bg-white rounded-full cursor-pointer -translate-y-12' onClick={openModal}>
                            <QuestionMarkCircleIcon aria-hidden="true" className="absolute top-1/2 left-1/2 h-8 w-8 text-primary -translate-x-1/2 -translate-y-1/2" />
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default Toast;
