import React, {useState} from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import {useMap} from '@vis.gl/react-google-maps';

function RecentMarkers(props) {
    const [panelOpen, setPanelOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const map = useMap();

    const colorMap = [
        { name: 'all', color: "border-primary" },
        { name: 'featured', color: "border-markerFeatured" },
        { name: 'interview', color: "border-markerInterview" },
        { name: 'memory', color: "border-markerMemory" },
        { name: 'soundscape', color: "border-markerSoundscape" },
    ]

    function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}

    function getDate(seconds) {
        const date = new Date(seconds * 1000);
        return date.toLocaleDateString();
    }

    function moveMap(latt, long) {
        map.setCenter({lat: parseFloat(latt), lng: parseFloat(long)});
    }

    return (
    <>
        <div className={classNames(
            panelOpen
            ? '-translate-x-none'
            : '-translate-x-full',
            'fixed top-0 left-0 z-1 w-full h-full md:w-1/2 xl:w-1/3 pt-28 px-8 bg-white transform transition duration-500'
        )}>
            <button
                className="absolute top-0 right-0 mt-8 py-4 px-6 text-p3 text-primary font-bold bg-sand shadow transform translate-x-full rotate-90 origin-bottom-left"
                onClick={() => setPanelOpen(!panelOpen)}
            >
                Recent Markers
            </button>

            <button
                className="absolute top-0 right-0 mt-28 mr-8"
                onClick={() => setPanelOpen(false)}
            >
                <XCircleIcon className="w-8 text-primary" />
            </button>

            <p className="mb-4 text-h5 text-primary font-medium">
                Sound Markers
            </p>

            <div className='h-full overflow-y-scroll'>
                <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className="block mb-8 border placeholder-gray-400 z-10 w-full px-4 py-3 leading-6 rounded active:z-10 focus:z-10 -mr-px border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50">
                    <option value="all">All</option>
                    <option value="featured">Featured</option>
                    <option value="interview">Interviews</option>
                    <option value="memory">Memories</option>
                    <option value="soundscape">Soundscapes</option>
                </select>

                <ul className="space-y-3 pb-40">
                    {props.markers.map((marker, index) => (
                        <div key={index}>
                            {marker.featured && (selectedCategory === 'all' || colorMap[marker.category].name === selectedCategory) &&
                                <div key={index} onClick={() => moveMap(marker.lattitude, marker.longitude)}>
                                    <li key={index}  className={classNames(
                                        'overflow-hidden rounded-md bg-sand px-6 py-4 border-4 shadow cursor-pointer',
                                        colorMap[marker.category] ? colorMap[marker.category].color : 'border-primary',
                                    )}>
                                        <p className="text-p2 text-primary font-bold">
                                            {marker.name}
                                        </p>
                                        <p className="text-p3 text-secondary">
                                            {getDate(marker.createdAt.seconds)}
                                        </p>
                                    </li>
                                </div>
                            }
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    </>
    );
}

export default RecentMarkers;
