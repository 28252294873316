import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon"
import { CloudArrowUpIcon, MicrophoneIcon } from '@heroicons/react/20/solid'
import { createItem, uploadFile } from "../util/db";
import { ReactMediaRecorder } from "react-media-recorder";

function AddSoundMarker(props) {
	const imageFileRef = useRef(null);
  	const [pending, setPending] = useState(false);
  	const [formAlert, setFormAlert] = useState(null);
  	const [audioFile, setAudioFile] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const [audioUpload, setAudioUpload] = useState(null);
	const [preview, setPreview] = useState();
  	const { register, handleSubmit, reset, errors } = useForm();

  	const [tabs, setTabs] = useState([
		{ id: 1, name: 'Record Audio', href: '#', icon: MicrophoneIcon, current: true },
		{ id: 2, name: 'Upload Audio', href: '#', icon: CloudArrowUpIcon, current: false },
	]);

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}

  	function changeTab(tabID) {
		const updatedTabs = tabs.map(tab => ({
			...tab,
			current: tab.id === tabID
		}));
		setTabs(updatedTabs);
	}

    useEffect(() => {
        if (!imageFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(imageFile)
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [imageFile]);

  const onSubmit = (data) => {
    setPending(true);

	const currentTab = tabs.filter(tabs => tabs.current === true);

	const audioToUpload = (currentTab[0].id === 1)
		? new File([audioUpload], `${data.name}${parseInt(data.lattitude)}${parseInt(data.longitude)}.wav`, {
			type: "audio/wav",
		  })
		: audioFile;

    uploadFile(audioToUpload).then((audioURL) => {
		uploadFile(imageFile).then((imageURL) => {

			const formData = {
				...data,
				...(audioURL && { audio: audioURL }),
				...(imageURL && { image: imageURL })
			};

			const query = createItem({ owner: "REbtxxK9VKWNLLBonFwSMEFJmgG2", ...formData });

			query
				.then(() => {
					// Let parent know we're done so they can hide modal
					props.onDone();

					setPending(false);
					// Clear form
					reset();

					// Reset the file input
    				if (imageFileRef.current) {
						imageFileRef.current.value = '';
	  				}
  
	  				// Optionally, clear the audioFile state as well
					setImageFile(null);

					// Show success alert message
					setFormAlert({
						type: "success",
						message: "Your Soundmarker has been submitted for review",
					});
				})
				.catch((error) => {
					// Hide pending indicator
					setPending(false);
					// Show error alert message
					setFormAlert({
						type: "error",
						message: error.message,
					});
				});
			}).catch((error) => {
				console.log(error);
			});
		}).catch((error) => {
			console.log(error);
	  	});
  	};

  	return (
    <div className='h-full w-full py-32 px-4 bg-white shadow-lg overflow-scroll'>

        <div className="max-w-7xl gap-y-8 px-4 sm:px-6 lg:px-8">
			<div className="mb-2">
				<h2 className="text-h5 text-primary">
					Add Sound Marker
				</h2>
			</div>

			{formAlert && (
				<div className="mb-2">
					<FormAlert
						type={formAlert.type}
						message={formAlert.message}
					/>
				</div>
			)}

			<form id="add-marker-form" className="mt-4 md:col-span-2 space-y-4" onSubmit={handleSubmit(onSubmit)}>
				<div>
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex space-x-8" aria-label="Tabs">
							{tabs.map((tab) => (
								<a
									key={tab.name}
									href={tab.href}
									className={classNames(
										tab.current
										? 'border-primary text-primary'
										: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
										'group inline-flex items-center border-b-2 py-2 px-1'
									)}
									aria-current={tab.current ? 'page' : undefined}
									onClick={() => changeTab(tab.id)}
								>
									<tab.icon
										className={classNames(
											tab.current ? 'text-primary' : 'text-gray-400 group-hover:text-gray-500',
											'-ml-0.5 mr-2 h-5 w-5'
										)}
										aria-hidden="true"
									/>
									<span>{tab.name}</span>
								</a>
							))}
						</nav>
      				</div>
    			</div>
				
				<ReactMediaRecorder
					audio={true}
					video={false}
					onStop={
						(blobUrl, blob) => {setAudioUpload(blob)}
					}
					render={({ status, startRecording, stopRecording, clearBlobUrl, mediaBlobUrl }) => (
						<div className={classNames(
							tabs[0].current
							? 'block'
							: 'hidden'
						)}>
							<div className="grid grid-cols-5 gap-2">
								<div className="grid grid-cols-2 gap-2 col-span-2">
									{status !== "recording" &&
										<Button onClick={startRecording} size="sm" className="w-full h-fit self-center text-sand bg-primary border-primary">
											Record
										</Button>
									}
									
									{status === "recording" &&
										<Button onClick={stopRecording} size="sm" className="w-full h-fit self-center text-sand bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700">
											Stop
										</Button>
									}

									<Button onClick={clearBlobUrl} size="sm" className="w-full h-fit self-center text-sand bg-primary border-primary">
										Reset
									</Button>
								</div>
								<div className="col-span-3">
									{mediaBlobUrl &&
										<audio src={mediaBlobUrl} controls className="w-full self-center" />
									}
								</div>
							</div>
						</div>
					)}
				/>

				<div className={classNames(
					tabs[1].current
					? 'block'
					: 'hidden'
				)}>
					<input
						type="file"
						id="audio"
						name="audio"
						className="w-full text-sm text-slate-500"
						onChange={(e) => { setAudioFile(e.target.files[0]) }}
						accept="audio/*"
					/>
				</div>

				<div className="h-1"></div>
				
				<label className="block text-slate-800" htmlFor={props.id}>
          			Upload Image
        		</label>
				
				{imageFile && 
					<div
						style={{ backgroundImage: `url(${preview})` }}
						className="h-40 w-full mb-4 rounded-lg bg-contain bg-center bg-no-repeat bg-slate-100"
					/>
				}

				<input
					type="file"
					id="image"
					name="image"
					className="w-full text-sm text-slate-500"
					onChange={(e) => { setImageFile(e.target.files[0]) }}
					accept="image/jpeg, image/png"
					ref={imageFileRef}
				/>
				
				<TextField
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Location Name"
                    error={errors.name}
                    inputRef={register({
                      required: "Please enter a name",
                    })}
                />
                <TextField
                    type="textarea"
                    id="description"
                    name="description"
                    placeholder="Description"
                    error={errors.description}
                    inputRef={register({
                      required: "Please enter a description",
                    })}
                />

                <div className="grid grid-cols-2 gap-4">
                    <TextField
                      	type="text"
                      	id="lattitude"
                      	name="lattitude"
                      	placeholder="Lattitude"
                      	defaultValue={props.lat}
                      	error={errors.lattitude}
                      	inputRef={register({
                        	required: "Please enter a lattitude",
                      	})}
						className="pointer-events-none"
                    />
                    <TextField
                      	type="text"
                      	id="longitude"
                      	name="longitude"
                      	placeholder="Longitude"
                      	defaultValue={props.lng}
                      	error={errors.longitude}
                      	inputRef={register({
                        	required: "Please enter a longitude",
                      	})}
						className="pointer-events-none"
                    />
                </div>

				<div className="pt-4 space-x-4 flex items-stretch">
					<Button
						type="submit"
						size="md"
						disabled={pending}
						isBlock={true}
						className="w-20 text-sand bg-primary border-primary"
					>
						{!pending && <>Add</>}

						{pending && <LoadingIcon className="w-5" />}
					</Button>
				</div>

			</form>

		</div>
    </div>
  );
}

export default AddSoundMarker;
