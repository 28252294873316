import React, { useState } from "react";
import {
  PencilSquareIcon,
  TrashIcon,
  BoltIcon,
  BoltSlashIcon,
} from "@heroicons/react/24/solid";
import FormAlert from "./FormAlert";
import Button from "./Button";
import EditItemModal from "./EditItemModal";
import { useAuth } from "./../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "./../util/db";

function DashboardItems(props) {
  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);

  const [creatingItem, setCreatingItem] = useState(false);
  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !items || items.length === 0;

  const handleStarItem = (item) => {
    updateItem(item.id, { featured: !item.featured });
  };

  return (
    <>
      {itemsError && (
        <div className="mb-4">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      <div>
        <div className="flex justify-between items-center py-4">
          <span className="text-h4 text-primary">
            SoundMarkers
          </span>
          <Button
            size="sm"
            variant="primary"
            onClick={() => setCreatingItem(true)}
          >
            Add Marker
          </Button>
        </div>

        {(itemsStatus === "loading" || itemsAreEmpty) && (
          <div className="p-8">
            {itemsStatus === "loading" && <>Loading...</>}

            {itemsStatus !== "loading" && itemsAreEmpty && (
              <>Nothing yet. Click the button to add your first item.</>
            )}
          </div>
        )}

        {itemsStatus !== "loading" && items && items.length > 0 && (
          <ul className="space-y-3">
            {items.map((item, index) => (
              <li key={index} className="overflow-hidden flex flex-row rounded-md bg-sand px-6 py-4 border-4 border-primary shadow">
                <p className="text-p2 text-primary">
                  {item.name}
                </p>
                <div className="flex items-center ml-auto space-x-3">
                  <button
                    className={
                      "w-5 h-5" +
                      (item.featured ? " text-green-600" : "") +
                      (!item.featured ? " text-red-600" : "")
                    }
                    onClick={() => handleStarItem(item)}
                  > 
                    {item.featured && 
                      <BoltIcon />
                    }
                    {!item.featured && 
                      <BoltSlashIcon />
                    }
                  </button>
                  <button
                    className="w-5 h-5 text-slate-600"
                    onClick={() => setUpdatingItemId(item.id)}
                  >
                    <PencilSquareIcon />
                  </button>
                  <button
                    className="w-5 h-5 text-slate-600"
                    onClick={() => deleteItem(item.id)}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {creatingItem && <EditItemModal onDone={() => setCreatingItem(false)} />}

      {updatingItemId && (
        <EditItemModal
          id={updatingItemId}
          onDone={() => setUpdatingItemId(null)}
        />
      )}
    </>
  );
}

export default DashboardItems;
