import React, { useState, useEffect } from "react";
import { Link } from "./../util/router";
import logo from "../images/logo.svg";
import logoMin from "../images/logo-min.svg";

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

function Navbar(props) {
  const windowWidth = useWindowWidth();

  return (
    <div className="fixed z-10 top-0 left-0 w-full bg-sand shadow-xl">
      <div className="relative container py-4">
        <div className="flex justify-between">
          <Link
            to="/"
            className="mx-auto"
          >
            <img
              className="relative mx-auto max-h-12"
              src={windowWidth < 768 ? logoMin : logo}
              alt="Sound Map"
            />
          </Link>
        </div>
        <div className="absolute top-1/2 right-0 pr-4 md:pr-0 -translate-y-1/2">
            <a href="/" className="pt-1 pr-6 text-p3 text-primary font-bold color-primary uppercase">
              Home
            </a>
            <a href="/about" className="pt-1 text-p3 text-primary font-bold color-primary uppercase">
              About
            </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
