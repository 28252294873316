import React, {useState} from 'react';
import icon1 from "../images/marker_1.svg";
import icon2 from "../images/marker_2.svg";
import icon3 from "../images/marker_3.svg";
import icon4 from "../images/marker_4.svg";
import icon5 from "../images/marker_5.svg";
import icon6 from "../images/marker_6.svg";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
import AudioPlayer from 'react-h5-audio-player';

function SoundMarker(props) {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const colorMap = [
    { id: 1, icon: icon1 },
    { id: 2, icon: icon2 },
    { id: 3, icon: icon3 },
    { id: 4, icon: icon4 },
    { id: 5, icon: icon5 },
    { id: 6, icon: icon6 },
  ]

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={{lat: props.lat, lng: props.lng}}
        title={props.title}
      >
        <img src={colorMap[props.color].icon} width={40} height={60} alt={props.title} />
      </AdvancedMarker>

      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          className='w-full max-w-96 md:w-[32rem]'
          onCloseClick={() => setInfowindowOpen(false)}
        >
          <div className='mb-6'>
            <h5 className='mb-2 font-sans text-h5 text-primary'>
              {props.title}
            </h5>
            <p className='mb-4 font-sans text-p3 text-primary'>
              lat<span className='hidden md:inline'>titude</span>: {String(props.lat).substring(0, 6)}
              <span className='ml-2'>long<span className='hidden md:inline'>itude</span>: {String(props.lng).substring(0, 6)}</span>
            </p>

            {props.image &&
              <div
                style={{ backgroundImage: `url(${props.image})` }}
                className="h-40 w-full mb-4 rounded-lg bg-gray-300 bg-cover bg-center"
              />
            }

            <p className='font-sans text-p3 text-primary'>
              {props.body}
            </p>
          </div>

          {props.audio && 
            <AudioPlayer
              src={props.audio}
              onPlay={e => console.log("onPlay")}
            />
          }
        </InfoWindow>
      )}
    </>
  );
}

export default SoundMarker;
