import React from "react";

function Footer(props) {

  const today = new Date();

  return (
    <div className="fixed bottom-0 left-0 w-full py-4 mt-auto text-sand bg-primary border-t-8 border-secondary shadow-xl">
      <footer className="container">
        <p className="text-center">
          &copy; {today.getFullYear()} Sound Map for a Changing Landscape
        </p>
      </footer>
    </div>
  );
}

export default Footer;
