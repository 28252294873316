import React, { useEffect, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from "./../util/auth";
import { useItem, updateItem, createItem, uploadFile, deleteFile } from "./../util/db";
import AudioPlayer from 'react-h5-audio-player';

function EditItemModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState();

  const { register, handleSubmit, errors } = useForm();

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: itemData, status: itemStatus } = useItem(props.id);

  useEffect(() => {
    if (!imageFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(imageFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [imageFile]);

  function handleRemoveImage(file) {
		deleteFile(file).then(() => {
			const query = updateItem(props.id, {image: ""});

			query
			.then(() => {
				setPreview("");
			})
			.catch((error) => {
				setFormAlert({
					type: "error",
					message: error.message,
				});
			});
		});
	}

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && itemStatus !== "success") {
    return null;
  }

  const onSubmit = (data) => {
    setPending(true);

    uploadFile(audioFile).then((audioURL) => {
      uploadFile(imageFile).then((imageURL) => {

      const formData = {
				...data,
				...(audioURL && { audio: audioURL }),
				...(imageURL && { image: imageURL })
			};

      const query = props.id
        ? updateItem(props.id, formData)
        : createItem({ owner: auth.user.uid, ...formData });

      query
        .then(() => {
          // Let parent know we're done so they can hide modal
          props.onDone();
        })
        .catch((error) => {
          // Hide pending indicator
          setPending(false);
          // Show error alert message
          setFormAlert({
            type: "error",
            message: error.message,
          });
        });
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <Transition appear={true} show={true}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={() => props.onDone()}
      >
        <div className="px-4 min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
              <Dialog.Title
                as="h3"
                className="text-h5 text-primary"
              >
                {props.id ? "Update" : "Add"} Marker
              </Dialog.Title>
              <div className="mt-4">
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert
                      type={formAlert.type}
                      message={formAlert.message}
                    />
                  </div>
                )}

                <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                    {itemData && itemData.image && !imageFile &&
                      <div className="relative">
                        <div
                          style={{ backgroundImage: `url(${itemData.image})` }}
                          className="h-40 w-full rounded-lg bg-gray-300 bg-cover bg-center"
                        />
                        <Button
                          type="button"
                          className="absolute top-2 right-2 px-0 py-0 m-0 bg-transparent rounded-sm border-0 hover:bg-transparent"
                          onClick={(e) => { handleRemoveImage(itemData.image) }}
                        >
                          <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </Button>
                      </div>
                    }
                    {imageFile && 
                        <div
                          style={{ backgroundImage: `url(${preview})` }}
                          className="h-40 w-full rounded-lg bg-gray-300 bg-cover bg-center"
                        />
                    }
                    
                    {(!itemData || (itemData && !itemData.image && !imageFile)) && 
                      <>
                        <p className="font-medium text-sm leading-6 text-slate-800">
                          Upload Image
                        </p>
                        <input
                          type="file"
                          id="imageURL"
                          name="imageURL"
                          className="w-full rounded-md bg-white/10 py-2 text-sm font-semibold text-slate-500 hover:bg-white/20"
                          onChange={(e) => { setImageFile(e.target.files[0]) }}
                          accept="image/jpeg, image/png"
                        />
                      </>
                    }

                  {(!itemData || (itemData && !itemData.audio)) && 
                    <>
                      <p className="font-medium text-sm leading-6 text-slate-800">
                        Upload Audio
                      </p>
                      <input
                        type="file"
                        id="audio"
                        name="audio"
                        label="Audio File"
                        className="w-full rounded-md bg-white/10 py-2 text-sm font-semibold text-slate-500 hover:bg-white/20"
                        onChange={(e) => { setAudioFile(e.target.files[0]) }}
                        accept="audio/*"
                      />
                    </>
                  }

                  {itemData && itemData.audio && 
                    <div className="relative">
                      <AudioPlayer
                        src={itemData.audio}
                        onPlay={e => console.log("onPlay")}
                      />
                    </div>
                  }

                  <TextField
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Location Name"
                    defaultValue={itemData && itemData.name}
                    error={errors.name}
                    inputRef={register({
                      required: "Please enter a name",
                    })}
                  />
                  <TextField
                    type="textarea"
                    id="description"
                    name="description"
                    placeholder="Description"
                    defaultValue={itemData && itemData.description}
                    error={errors.description}
                    inputRef={register({
                      required: "Please enter a description",
                    })}
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      type="text"
                      id="lattitude"
                      name="lattitude"
                      placeholder="Lattitude"
                      defaultValue={itemData && itemData.lattitude}
                      error={errors.lattitude}
                      inputRef={register({
                        required: "Please enter a lattitude",
                      })}
                    />
                    <TextField
                      type="text"
                      id="longitude"
                      name="longitude"
                      placeholder="Longitude"
                      defaultValue={itemData && itemData.longitude}
                      error={errors.longitude}
                      inputRef={register({
                        required: "Please enter a longitude",
                      })}
                    />
                  </div>

                  <div>
                    {/* <label htmlFor="category" className="block mb-1 text-slate-800">
                      Color
                    </label> */}
                    <select
                      id="category"
                      name="category"
                      className="block border placeholder-gray-400 z-10 w-full px-4 py-3 leading-6 rounded active:z-10 focus:z-10 -mr-px border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                      defaultValue={itemData && itemData.category}
                      error={errors.category}
                      ref={register()}
                    >
                      <option value="1">Featured</option>
                      <option value="2">Interview</option>
                      <option value="3">Memory</option>
                      <option value="4">Soundscape</option>
                    </select>
                  </div>

                  <div className="space-x-2 flex items-stretch">
                    <Button
                      size="md"
                      variant="simple"
                      onClick={() => props.onDone()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      disabled={pending}
                      isBlock={true}
                      className="w-20 text-sand bg-primary border-primary"
                    >
                      {!pending && <>Save</>}

                      {pending && <LoadingIcon className="w-5" />}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditItemModal;
