import React from "react";
import Section from "./Section";
import DashboardItems from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

function DashboardSection(props) {
  const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container pt-12">
        <div className="p-4 w-full">
          <DashboardItems />
        </div>
        <div className="p-4 w-full">
          <div className="py-6 rounded border-t border-gray-200 prose prose-a:text-blue-600 max-w-none">
            <h3 className="mb-4">Extra debug info</h3>
            <div>
              You are signed in as <strong>{auth.user.email}</strong>.
            </div>

            <div>
              You can change your account info{` `}
              {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
              in{` `}
              <Link to="/settings/general">settings</Link>
              <br />
              <Link onClick={(e) => auth.signout()}>Signout</Link>
            </div>

          </div>
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
