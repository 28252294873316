import React from "react";
import Meta from "../components/Meta";

const supporters = [
  {
    id: 1,
    name: 'Barry Art Museum',
    logo: 'https://storage.googleapis.com/soundmap/bam.png',
    url: 'https://barryartmuseum.odu.edu/'
  },
  {
    id: 2,
    name: 'CHS Containers',
    logo: 'https://storage.googleapis.com/soundmap/chs.png',
    url: 'https://chs-containergroup.de/en/'
  },
  {
    id: 3,
    name: 'Carrol Trucking',
    logo: 'https://storage.googleapis.com/soundmap/ctg.png',
    url: 'https://www.carroll-trucking.com/'
  },
  {
    id: 4,
    name: 'Lady Fern’s Native Plants',
    logo: 'https://storage.googleapis.com/soundmap/lfnp.png',
    url: 'https://vnps.org/native-plant-nursery-list/entry/8743/'
  },
  {
    id: 5,
    name: 'Southern Branch Nursery',
    logo: 'https://storage.googleapis.com/soundmap/sbn.png',
    url: 'https://southernbranchnursery.com/'
  },
  {
    id: 6,
    name: 'Virginia Arts Commission',
    logo: 'https://storage.googleapis.com/soundmap/vca.png',
    url: 'https://vca.virginia.gov/'
  },
  {
    id: 7,
    name: 'National Endowment for the Arts',
    logo: 'https://storage.googleapis.com/soundmap/nefta.png',
    url: 'https://www.arts.gov/'
  },
  {
    id: 8,
    name: 'ODU Office of Academic Affairs',
    logo: 'https://storage.googleapis.com/soundmap/odu.png',
    url: "https://www.odu.edu/acadaffairs"
  },
  {
    id: 9,
    name: 'ODU M-Collective',
    logo: 'https://storage.googleapis.com/soundmap/aodu.png',
    url: 'https://www.odu.edu/life/culture/arts'
  },
]



function AboutPage(props) {

  return (
    <>
      <Meta title="About Sound Map | For a Changing Landscape" />

      <div className="bg-white mb-40">

        <main className="isolate">

          <div className="relative isolate -z-10 overflow-hidden pt-4">
            <div className="mx-auto max-w-7xl px-6 pt-32 sm:pt-40 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                <h1 className="max-w-2xl text-4xl font-bold text-primary sm:text-6xl lg:col-span-2 xl:col-auto">
                  Sound Map for a Changing Landscape is a <span className="text-secondary">socially engaged art</span> project.
                </h1>
                <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                  <p className="mb-4 text-xl leading-8 text-gray-600">
                    We invite communities to share sounds, images, and narratives around changing land via this digital archive interface, and public art installations.
                  </p>
                  <p className="mb-4 text-lg leading-8 text-gray-600">
                    Using this digital platform, participants are able to pin different locations, uploading sound recordings and imagery related to that place. These contributions can be interviews, original writings, memories, or field recordings. Images could represent the place itself, memories related to it, and/or any contributors and participants. These artifacts will create a digital archive of the participants’ relationship to the changing landscape.
                  </p>
                </div>
                <img
                  alt=""
                  src="https://storage.googleapis.com/soundmap/about1.jpg"
                  className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                />
              </div>
            </div>
          </div>

          <div className="relative isolate -z-10 overflow-hidden pt-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                <h2 className="max-w-2xl text-4xl font-bold text-primary sm:text-5xl lg:col-span-2 xl:col-auto">
                  An installation space has been created in and around a 20<span className="text-secondary">-</span>foot shipping container
                </h2>
                <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                  <p className="mb-4 text-lg leading-8 text-gray-600">
                    Converting this piece of infrastructure into a gallery and native plant habitat with green roof and planters, the installation invites viewers to linger and listen. Sound from the digital archive will be broadcast into the container, as well as out from speakers inside the planters. Participants and viewers are invited to listen deeply, to engage with the world through the empathetic process of listening.
                  </p>
                  <p className="mb-4 text-lg leading-8 text-gray-600">
                    This project draws from the traditions of community-based knowledge and participatory mapping, “in which mapping efforts draw on the knowledge and experience of people who are generally not conceived of as cartographers” <span className="italic">(E. Iralu, 2021)</span>. Both the wealth of experience in everyday life and the wealth of experience in the more than human world are often overlooked sites of knowledge. We hope that by focusing on them as subjects of close listening, we can open ourselves to new ways of relating to each other and the world.
                  </p>
                </div>
                <img
                  alt=""
                  src="https://storage.googleapis.com/soundmap/about2.jpg"
                  className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                />
              </div>
            </div>
          </div>






          {/* Logo cloud */}
          <div className="mx-auto mt-20 max-w-7xl sm:px-6 lg:px-8">
            <div className="relative isolate overflow-hidden bg-primary px-6 py-24 pt-20 text-center sm:rounded-3xl sm:px-8">
              <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Financial and Material Supporters
              </h2>
              <div className="mx-auto mt-20 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-12 sm:max-w-xl md:grid-cols-4 sm:gap-x-10 sm:gap-y-14 lg:max-w-4xl">
                {supporters.map((supporter) => (
                  <a href={supporter.url} target="_blank" rel="noreferrer">
                    <img
                      alt={supporter.name}
                      src={supporter.logo}
                      width={158}
                      height={48}
                      className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Content section */}
          <div className="mt-32 overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                  <h2 className="mb-4 text-3xl font-bold text-primary sm:text-4xl">
                    Acknowledgements <span className="text-secondary">/</span> Credits
                  </h2>
                  <ul className="mb-4 text-lg text-gray-600">
                    <li className="mb-1">
                      <strong>Studio Assistants:</strong>
                      <br />
                      Theo Mayberry, Dylan Parrish, Daniel Penzaloa-Bueno, Anna Syrgayaki, Maddie Sznoluch, Kyle Res
                    </li>
                    <li className="mb-1">
                      <strong>Friends and Neighbors:</strong>
                      <br />
                      Tom Dunleavy, Randy Polladian, Wes Moore, Nicole Moore, Jason Edelman, Jordan Crawford, Dan Barshis, Stephanie Peglow, Madeline Alden, Collum Sutherland, Molly LaRocco, Jason Demeter, Kelsey Velo, Chantal Thomas
                    </li>
                    <li className="mb-1">
                      <strong>Logistics and Donations:</strong>
                      <br />
                      Edward Shea, Andrew Smith
                    </li>
                    <li className="mb-1">
                      <strong>Graphic Designer:</strong>
                      <br />
                      Tim Delrosario
                    </li>
                    <li className="mb-1">
                      <strong>Web Developer:</strong>
                      <br />
                      Patrick Hill
                    </li>
                    <li className="mb-1">
                      <strong>Technical Assistance:</strong>
                      <br />
                      Will Truran, Anthony D’Angelo, John Roth, John Little, Ed Snodgrass
                    </li>
                  </ul>
                </div>
                <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                  <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img
                      alt=""
                      src="https://storage.googleapis.com/soundmap/about3.jpg"
                      className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </div>
                  <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                    <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                      <img
                        alt=""
                        src="https://storage.googleapis.com/soundmap/about4.jpg"
                        className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                      />
                    </div>
                    <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                      <img
                        alt=""
                        src="https://storage.googleapis.com/soundmap/about5.jpg"
                        className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                      />
                    </div>
                    <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                      <img
                        alt=""
                        src="https://storage.googleapis.com/soundmap/about6.jpg"
                        className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="mb-4 text-3xl font-bold text-primary sm:text-4xl">
                About the Artists
              </h2>
            </div>
            <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
              <li className="flex flex-col gap-10 pt-12 sm:flex-row">
                <img alt="Brendan Baylor" src="https://storage.googleapis.com/soundmap/brendan.jpg" className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />
                <div className="max-w-xl flex-auto">
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    <span className="font-semibold leading-8 text-gray-900">Brendan Baylor, lead artist and builder</span> grew up in the Pacific Northwest, taking in the sights and sounds of the wetlands next to his childhood home. As an interdisciplinary artist, his work explores landscapes as social, historical, and ecological spaces. His work has been shown nationally and internationally, including the Madison Museum of Contemporary Art, the Chrysler Museum of Art, and the CONA Institute in Ljubljana, Slovenia. His artistic and curatorial work has been covered by NPR, the New York Times, and the Guardian. Brendan is currently Associate Professor of Art (Print Media) at Old Dominion University. He lives and works in Norfolk, VA at the mouth of the Chesapeake Bay. More of his work can be seen at <a href="https://www.brendanbaylor.art/" target="_blank" rel="noreferrer" className='text-primary'>www.brendanbaylor.art</a> and <a href="https://www.hothouseartproject.com/" target="_blank" rel="noreferrer" className='text-primary'>www.hothouseartproject.com</a>
                  </p>
                </div>
              </li>
              <li className="flex flex-col gap-10 pt-12 sm:flex-row">
                <img alt="Kelly Morse" src="https://storage.googleapis.com/soundmap/kelly.jpg" className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />
                <div className="max-w-xl flex-auto">
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    <span className="font-semibold leading-8 text-gray-900">Kelly Morse</span> is a landscape designer, award-winning author, and founder of 70|30 Design Studio. She is a Sustainable Arts Foundation Fellow and a Robert Pinsky Global Fellow, as well as a Scotty Scholar recipient from the Chesapeake Conservation Landscaping Council. At 70|30 Design Studio Kelly combines art and ecology to create landscape designs that celebrate the unique nature of the Mid-Atlantic coastal plain. Her favorite part of living in Norfolk, VA is observing birds on the brackish rivers that wind through the city. More of her work can be seen at <a href="https://www.7030design.com/" target="_blank" rel="noreferrer" className='text-primary'>www.7030design.com</a> and <a href="https://www.hothouseartproject.com/" target="_blank" rel="noreferrer" className='text-primary'>www.hothouseartproject.com</a>
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mx-auto mt-20 md:mt-32 max-w-7xl px-6 lg:px-8">
            <img alt="Brendan Baylor" src="https://storage.googleapis.com/soundmap/about7.jpg" className="aspect-[8/6] md:aspect-[8/3] w-full flex-none rounded-2xl object-cover" />
          </div>
         
        </main>
      </div>
    </>
  )
}

export default AboutPage;
