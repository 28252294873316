import React, {useState} from "react";
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';
import SoundMarker from './SoundMarker';
import AddSoundMarker from './AddSoundMarker';
import RecentMarkers from './RecentMarkers';
import { XCircleIcon } from '@heroicons/react/20/solid';
import icon1 from "../images/marker_1.svg";
import { useItems } from "./../util/db";

function MapSection(props) {
    const [panelOpen, setPanelOpen] = useState(false);
    const [tempMarker, setTempMarker] = useState([]);
    
    function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useItems();

    function placeMarkerAndPanTo(e) {
		setTempMarker(
            [{ id: 1, lat: e.detail.latLng.lat, lng: e.detail.latLng.lng }]
        );
        setPanelOpen(true);
	}

    return (
        <>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Map
                    mapId={'9cf965f380bdddc3'}
                    style={{width: '100vw', height: '100vh'}}
                    defaultCenter={{lat: 39.8283, lng: -98.5795}}
                    defaultZoom={5}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    reuseMaps={true}
                    clickableIcons={false}
                    onClick={(e) => placeMarkerAndPanTo(e)}
                >

                    {tempMarker.map(place => {
                        return (
                            <AdvancedMarker
                                key={place.id}
                                position={{ lat: place.lat, lng: place.lng }}
                            >
                                <img src={icon1} width={40} height={60} alt={props.title} />
                            </AdvancedMarker>
                        );
                    })}
                    
                    {itemsStatus !== "loading" && items && items.length > 0 && (
                        <>
                            {items.map((item, index) => (
                                <div key={index}>
                                    {item.featured &&
                                        <SoundMarker
                                            title={item.name}
                                            body={item.description}
                                            lat={parseFloat(item.lattitude)}
                                            lng={parseFloat(item.longitude)}
                                            color={item.category ? item.category : 0}
                                            audio={item.audio}
                                            image={item.image}
                                        />
                                    }
                                </div>
                            ))}
                        </>
                    )}
                </Map>
                {itemsStatus !== "loading" && items && items.length > 0 && (
                    <RecentMarkers markers={items} />
                )}
            </APIProvider>

            <div className={classNames(
                panelOpen
                ? '-translate-x-none'
                : '-translate-x-full',
                'fixed top-0 left-0 z-1 w-full h-full md:w-1/2 xl:w-1/3 transform transition duration-500'
            )}>
                <button
                    className="absolute top-0 right-0 mt-28 mr-8"
                    onClick={() => setPanelOpen(false)}
                >
                    <XCircleIcon className="w-8 text-primary" />
                </button>
                
                <AddSoundMarker
                    onDone={() => {
                        setTimeout(() => {
                            setPanelOpen(false);
                        }, 5000);
                    }}
                    lat={tempMarker[0] && tempMarker[0].lat}
                    lng={tempMarker[0] && tempMarker[0].lng}
                />
            </div>
        </>
    );
}

export default MapSection;
