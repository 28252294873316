import React from "react";
import Meta from "./../components/Meta";
import MapSection from "./../components/MapSection";
import Toast from "./../components/Toast";

function IndexPage(props) {
  return (
    <>
      <Meta title="Sound Map | For a Changing Landscape" />
      <MapSection />
      <Toast />
    </>
  );
}

export default IndexPage;
